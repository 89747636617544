
const digest = '0fdb778801cb367889f17589d735f3043de8455f022388c1bb7957244f38dd87';
const css = `._timesheetTableActions_hd6fx_1 {
  display: flex;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"timesheetTableActions":"_timesheetTableActions_hd6fx_1"};
export { css, digest };
  