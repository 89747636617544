
const digest = '20eb727e4d4b992121d29b258289d84e5ee345ffbb1ad057f7e647213d055df0';
const css = `._spinner_3vod1_1 {
  width: 2rem;
  height: 2rem;
  color: var(--color-brand);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"spinner":"_spinner_3vod1_1"};
export { css, digest };
  